import React from 'react'
import { graphql } from "gatsby"

import Layout from '../../components/layout'
import Navbar from '../../components/Navbar'
import Hero from '../../components/Hero'

const ThanksPage = ({ data }) => (
  <Layout>
    <Navbar customNav />
    <Hero heading="Thank you for getting in touch"
      image={data.heroImage.childImageSharp.fluid.src} fullHeight
      buttonText="Back to Homepage" buttonTarget="/" />
  </Layout>
)

export const gqlData = graphql`
query thankYouPageEn{
  heroImage: 
    file(relativePath: { eq: "francesco-gallarotti-2498-unsplash.jpg"}){
      childImageSharp{
        fluid(maxWidth: 1200, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
}`

export default ThanksPage